@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Newsreader:opsz@6..72&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: "Inter";
  background: #faf4eb;
  color: rgba(0, 0, 0, 0.72);
}
